<template>
  <a-spin :spinning="isloading" tip="处理中">
    <div style="padding: 10px">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="工资查询">
          <salarysearch ref="salarysearch" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="年度奖金补贴查询">
          <yearsearch ref="yearsearch" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="公告栏">
          <salarynoticelist ref="notelist" noticetype="salary" />
        </a-tab-pane>
        <template slot="tabBarExtraContent">
          <a-upload
            name="file"
            accept=".xls,.xlsx"
            :showUploadList="false"
            :beforeUpload="beforeUpload1"
            :customRequest="selfUpload"
          >
            <p-button
              :roles="['工资-导入']"
              type="primary"
              style="margin-right: 5px"
              name="导入工资"
            />
          </a-upload>
          <a-upload
            name="file"
            accept=".xls,.xlsx"
            :showUploadList="false"
            :beforeUpload="beforeUpload2"
            :customRequest="selfUpload"
          >
            <p-button
              :roles="['工资-导入']"
              type="primary"
              style="margin-right: 5px"
              name="导入年度奖金补贴"
            />
          </a-upload>
          <p-button :roles="['工资-发布公告']" type="default" @click="showAddNotice" name="发布公告"></p-button>
        </template>
      </a-tabs>
      <a-modal
        title="公告信息"
        v-model="noticemodalvisible"
        width="90%"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
      >
        <salarynotice @callback="modelCallBack" noticetype="salary" />
      </a-modal>
    </div>
  </a-spin>
</template>
<script>
import salarynotice from "./salarynotice";
import salarynoticelist from "./salarynoticelist";
import salarysearch from "./salarysearch.vue";
import yearsearch from "./yearsearch.vue";
import salary from "@/api/personal/salary";
import yearsalary from "@/api/personal/yearsalary";
export default {
  name: "salary",
  components: { salarynotice, salarynoticelist, salarysearch, yearsearch },
  data() {
    return {
      noticemodalvisible: false,
      isloading: false,
    };
  },
  mounted() {},
  methods: {
    beforeUpload2(file) {
      this.isloading = true;
      this.upload2(file, true);
      return true;
    },
    beforeUpload1(file) {
      this.isloading = true;
      this.upload1(file, true);
      return true;
    },
    selfUpload() {},
    upload1(file, bol) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("onlyVerify", bol);
      salary.importExcel1(formData).then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          if (bol) {
            if (res.data > 0) {
              this.$confirm({
                title: "确认提示",
                icon: "",
                content: "检测到" + res.data + "条数据待导入，点击确认完成导入",
                okText: "确认",
                cancelText: "取消",
                onOk: () => {
                  this.upload1(file, false);
                },
                onCancel: () => {
                  this.isloading = false;
                },
              });
              return
            }
            this.isloading = false;
            this.$message.error("没有需要导入的数据");
            return;
          }
          this.isloading = false;
          this.$refs.salarysearch.getPageList();
        } else {
          this.isloading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    upload2(file, bol) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("onlyVerify", bol);
      yearsalary.importExcel(formData).then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          if (bol) {
            if (res.data > 0) {
              this.$confirm({
                title: "确认提示",
                icon: "",
                content: "检测到" + res.data + "条数据待导入，点击确认完成导入",
                okText: "确认",
                cancelText: "取消",
                onOk: () => {
                  this.upload2(file, false);
                },
                onCancel: () => {
                  this.isloading = false;
                },
              });
              return
            }
            this.isloading = false;
            this.$message.error("没有需要导入的数据");
            return;
          }
          this.isloading = false;
          this.$refs.yearsearch.getPageList();
        } else {
          this.isloading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    showAddNotice() {
      this.noticemodalvisible = true;
    },
    modelCallBack() {
      this.noticemodalvisible = false;
      this.$refs.notelist.GetList();
    },
  },
};
</script>
