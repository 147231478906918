<template>
  <div class="tableList">
    <div class="search">
      <div class="search-body">
        <a-form-model layout="inline">
          <a-form-model-item label="日期">
            <a-range-picker @change="onTimeChange" />
          </a-form-model-item>
          <a-form-model-item>
            <p-button type="primary" name="搜索" @click="searchList" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="default" @click="exportData">导出</a-button>
          </a-form-model-item>
          <a-form-model-item v-if="selectRows.length > 0">
            <p-button
              :roles="['工资-导入']"
              type="primary"
              name="删除"
              @click="batchDelete"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <div class="tableItem">
      <a-table
        rowKey="id"
        :columns="tableInfo.columns"
        :data-source="tableInfo.datas"
        :pagination="tableInfo.pagination"
        :loading="loading"
        @change="onChange"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ x: true }"
        :rowSelection="{
          type: 'checkbox',
          columnWidth: 40,
          onChange: onRowChange,
          selectedRowKeys: selectRows,
        }"
      >
      </a-table>
    </div>
  </div>
</template>
  <script>
import salaryApi from "@/api/personal/salary";
const columns = [
  {
    dataIndex: "month",
    align: "center",
    title: "年月",
    width: 100,
    fixed: "left",
  },
  {
    dataIndex: "realName",
    align: "center",
    title: "姓名",
    width: 90,
    fixed: "left",
  },
  {
    dataIndex: "gangweigongzi",
    align: "center",
    title: "岗位工资",
    width: 100,
  },

  {
    dataIndex: "xinjigongzi",
    align: "center",
    title: "薪级工资",
    width: 100,
  },
  {
    dataIndex: "jichujixiao",
    align: "center",
    title: "基础绩效",
    width: 100,
  },

  {
    dataIndex: "jianglijixiao",
    align: "center",
    title: "奖励绩效",
    width: 100,
  },
  {
    dataIndex: "buchongjixiao",
    align: "center",
    title: "补充绩效",
    width: 100,
  },
  {
    dataIndex: "wuyebutie",
    align: "center",
    title: "物业补贴",
    width: 100,
  },
  {
    dataIndex: "tejijiaoshi",
    align: "center",
    title: "特级教师",
    width: 100,
  },
  {
    dataIndex: "xilifei",
    align: "center",
    title: "洗理费",
    width: 90,
  },
  {
    dataIndex: "heji",
    align: "center",
    title: "应发合计",
  },
  {
    dataIndex: "daikouhuifei",
    align: "center",
    title: "代扣会费",
  },
  {
    dataIndex: "gongjijin",
    align: "center",
    title: "公积金",
  },
  {
    dataIndex: "geshui",
    align: "center",
    title: "个税",
  },
  {
    dataIndex: "yanglaobaoxian",
    align: "center",
    title: "养老保险",
  },
  {
    dataIndex: "zhiyenianjin",
    align: "center",
    title: "职业年金",
  },

  {
    dataIndex: "shiyebaoxian",
    align: "center",
    title: "失业保险",
  },
  {
    dataIndex: "yibao",
    align: "center",
    title: "医保",
  },
  {
    dataIndex: "shifa",
    align: "center",
    title: "实发金额",
  },
  {
    dataIndex: "remark",
    align: "center",
    title: "备注1",
  },
  {
    dataIndex: "remark2",
    align: "center",
    title: "备注2",
  },
  {
    dataIndex: "remark3",
    align: "center",
    title: "备注3",
  },
];

export default {
  name: "salarysearch",
  data() {
    return {
      loading: false,
      search: {},
      tableInfo: {
        datas: [],
        columns,
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: false,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `总共${total}条`,
        },
      },
      selectRows: [],
    };
  },

  mounted() {
    this.getPageList();
  },
  methods: {
    exportData() {
      var _this = this;
      _this.loading = true;
      salaryApi.exportData({ ...this.search }, (d) => {
        _this.loadingTip = "已完成" + d + "%";
        if (d == 100) {
          setTimeout(function () {
            _this.loading = false;
          }, 2000);
        }
      });
    },
    onRowChange(selectedRowKeys) {
      this.selectRows = selectedRowKeys;
    },
    batchDelete() {
      salaryApi.deleteBatch({ id: this.selectRows.join(",") }).then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          this.$message.success(res.errorMsg);
          this.getPageList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    async getPageList() {
      let param = {
        ...this.search,
        pageNow: this.tableInfo.pagination.current,
        pageSize: this.tableInfo.pagination.pageSize,
      };
      salaryApi.getSalaryList(param).then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          this.tableInfo.datas = res.data.records;
          this.tableInfo.pagination.total = Number(res.data.total);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    onTimeChange(date, dateString) {
      this.$set(this.search, "startTime", dateString[0]);
      this.$set(this.search, "endTime", dateString[1]);
    },
    onChange(page) {
      let _this = this;
      _this.tableInfo.pagination.current = page.current;
      _this.tableInfo.pagination.pageSize = page.pageSize;
      _this.getPageList();
    },
    searchList() {
      let _this = this;
      _this.getPageList();
    },
  },
};
</script>

  <style lang="less">
</style>
