import request from '@/request';
const part_url = "/oa/salaryYear/";

export default {
  importExcel(params) {
    let url = part_url + "importExcel";
    return request.upload(url, params)
  },
  exportData(params, onProgress, onError) {
    return request.downloadFile(part_url + 'exportExcel', params, '年度奖金补贴.xls', onProgress, onError);
  },
  getList(params) {
    let url = part_url + "getList";
    return request.get(url, params, {});
  },
  deleteBatch(params) {
    let url = part_url + "deleteBatch";
    return request.post(url, params, {});
  }
}
