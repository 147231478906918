<template>
  <div class="tableList">
    <div class="search">
      <div class="search-body">
        <a-form-model layout="inline">
          <a-form-model-item label="年度">
            <a-input v-model="search.year" />
          </a-form-model-item>
          <a-form-model-item>
            <p-button type="primary" name="搜索" @click="searchList" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="default" @click="exportData">导出</a-button>
          </a-form-model-item>
          <a-form-model-item v-if="selectRows.length > 0">
            <p-button
              :roles="['工资-导入']"
              type="primary"
              name="删除"
              @click="batchDelete"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <div class="tableItem">
      <a-table
        rowKey="id"
        :columns="tableInfo.columns"
        :data-source="tableInfo.datas"
        :pagination="tableInfo.pagination"
        :loading="loading"
        @change="onChange"
        :locale="{ emptyText: '暂无数据' }"
        :rowSelection="{
          type: 'checkbox',
          columnWidth: 40,
          onChange: onRowChange,
          selectedRowKeys: selectRows,
        }"
      >
      </a-table>
    </div>
  </div>
</template>
  <script>
import yearsalaryApi from "@/api/personal/yearsalary";
const columns = [
  {
    dataIndex: "realName",
    align: "center",
    title: "姓名",
  },
  {
    dataIndex: "jiangjin",
    align: "center",
    title: "奖金",
  },
  {
    dataIndex: "butie",
    align: "center",
    title: "补贴",
  },
  {
    dataIndex: "month",
    align: "center",
    title: "发放时间",
  },
];

export default {
  name: "yearsearch",
  data() {
    return {
      loading: false,
      search: {},
      tableInfo: {
        datas: [],
        columns,
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: false,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `总共${total}条`,
        },
      },
      selectRows: [],
    };
  },

  mounted() {
    this.getPageList();
  },
  methods: {
    exportData() {
      var _this = this;
      _this.loading = true;
      yearsalaryApi.exportData({ ...this.search }, (d) => {
        _this.loadingTip = "已完成" + d + "%";
        if (d == 100) {
          setTimeout(function () {
            _this.loading = false;
          }, 2000);
        }
      });
    },
    onRowChange(selectedRowKeys) {
      this.selectRows = selectedRowKeys;
    },
    batchDelete() {
      yearsalaryApi
        .deleteBatch({ id: this.selectRows.join(",") })
        .then((res) => {
          if (res.errorCode === this.$commons.RespCode.success) {
            this.$message.success(res.errorMsg);
            this.getPageList();
          } else {
            this.$message.error(res.errorMsg);
          }
        });
    },
    async getPageList() {
      let param = {
        pageNow: this.tableInfo.pagination.current,
        pageSize: this.tableInfo.pagination.pageSize,
        ...this.search,
      };
      yearsalaryApi.getList(param).then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          this.tableInfo.datas = res.data.records;
          this.tableInfo.pagination.total = Number(res.data.total);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    onTimeChange(date, dateString) {
      this.seach.startTime = dateString[0];
      this.seach.endTime = dateString[1];
    },
    onChange(page) {
      let _this = this;
      _this.tableInfo.pagination.current = page.current;
      _this.tableInfo.pagination.pageSize = page.pageSize;
      _this.getPageList();
    },
    searchList() {
      let _this = this;
      _this.getPageList();
    },
  },
};
</script>
  
  <style lang="less">
</style>